<!--  -->
<template>
  <div style="height: 100%">
    <el-card style="height: 100%">
      <div class="top">
        筛选专题：
        <el-select
          v-model="value"
          placeholder="请选择专题"
          size="mini"
          filterable
        >
          <el-option
            v-for="item in specialList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          size="mini"
          style="margin-left: 30px"
          @click="OnReset(0)"
          >搜索</el-button
        >
     
        <el-button size="mini" style="margin-left: 30px" @click="OnReset(1)"
          >重置</el-button
        >
        <el-button type="primary" class="set" size="mini" @click="OnSet(1)"
          >提出疑问</el-button
        >
      </div>
      <el-empty description="暂无数据" v-if="lists.length == 0"></el-empty>
      <el-scrollbar style="height: 80%"  v-if="lists.length > 0">
        <el-collapse v-model="activeName" accordion @change="changecollapse()">
          <el-collapse-item
            :title="item.themeTitle"
            :name="item.id"
            class="box"
            v-for="(item, index) in lists"
            :key="index"
          >
            <div class="collapse">
              <div class="icon">{{ item.fullName.split("")[0] }}</div>
              <div class="con">
                <span class="name">{{ item.fullName }}</span>
                <span class="time">{{
                  currentTime.formatDate(item.createTime * 1000)
                }}</span>
                <div class="title" v-html="item.title">
                  {{ item.title }}
                </div>
              </div>
            </div>
            <div
              class="collapse"
              v-for="(ite, inde) in item.answers"
              :key="inde"
            >
              <div class="icon">{{ item.fullName.split("")[0] }}</div>
              <div class="con">
                <span class="name">{{ ite.fullName }}</span>
                <span class="time">{{
                  currentTime.formatDate(ite.createTime * 1000)
                }}</span>
                <div class="title" v-html="ite.answer">
                  {{ ite.answer }}
                </div>
              </div>
            </div>
            <div style="padding-right: 12px">
              <mceEditor v-model="Anquery" ref="query" :height="300" />
            </div>

            <div class="button">
              <el-button type="primary" style="width: 108px" @click="go"
                >提交</el-button
              >
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-scrollbar>
      <!-- //分页 -->

      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="page.pageSize"
        :current-page.sync="page.pageNum"
        layout="total, prev, pager, next"
        :total="page.total"
        background
        style="margin-top: 20px; text-align: center"
      ></el-pagination>
    </el-card>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.dialogVisible"
      width="50%"
    >
      <div class="dialog">
        <el-form
          ref="formLabelAlign"
          :rules="rules"
          label-position="right"
          label-width="100px"
          :model="formLabelAlign"
        >
          <el-form-item label="科目：" prop="subject">
            <el-select
              disabled
              v-model="formLabelAlign.subject"
              placeholder="请选择学科"
              size="mini"
              filterable
            >
              <el-option
                v-for="item in subjectList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="专题：" prop="special">
            <el-select
              v-model="formLabelAlign.special"
              placeholder="请选择专题"
              size="mini"
              filterable
            >
              <el-option
                v-for="item in specialList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="疑问：">
            <mceEditor
              v-model="formLabelAlign.query"
              ref="query"
              :height="300"
            />
          </el-form-item>
        </el-form>
      </div>
      <div class="bottom">
        <el-button type="primary" @click="OnDialogSet">确认</el-button>
        <el-button @click="OnDialogSet(1)">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import mceEditor from "../../materialGather/k12SetQuestion/mceEditor.vue";
import {
  answerListQuestionAnswerTheme,
  questionAnswerCreateQuestion,
  questionAnswerSaveAnswer,
  questionAnswerGetQuestionAnswerPage,
} from "../../../api/api";
export default {
  data() {
    return {
      value: "",
      activeName: "",
      dialog: {
        dialogVisible: false,
        title: "提出疑问",
      },
      subjectList: [],
      specialList: [{ value: "", label: "全部" }],
      formLabelAlign: {
        subject: "", //科目
        special: "", //专题
        query: "", //疑问
        id: null,
      },
      rules: {
        subject: [
          { required: true, message: "科目不可为空", trigger: "change" },
        ],
        special: [
          { required: true, message: "专题不可为全部", trigger: "change" },
        ],
      },
      page: {
        pageSize: 10,
        total: 100,
        pageNum: 1,
      },
      lists: [],
      Anquery: "", //回复的消息
    };
  },

  components: {
    mceEditor,
  },
  mounted() {
    this.subjectList.push({
      label: this.$route.query.subjectName,
      value: this.$route.query.subjects,
    });
    this.init();
  },
  watch: {
    "dialog.dialogVisible": {
      handler(newValue, oldValue) {
        if (newValue == false) {
          this.$refs["formLabelAlign"].resetFields();
        }
      },
    },
  },
  methods: {
    init() {
      answerListQuestionAnswerTheme({
        classId: this.$route.query.classId,
      }).then((res) => {
        console.log(res);
        if (res.message == "请求成功") {
          res.data.map((item) => {
            this.specialList.push({
              label: item.title,
              value: item.id,
            });
          });
        }
      });
      this.list();
    },
    changecollapse() {
      if (this.activeName == "") {
        this.Anquery = "";
      }
    },
    go() {
      if (this.Anquery == "") {
        this.$message.error("内容不可为空");
        return;
      }
      questionAnswerSaveAnswer({
        questionId: this.activeName,
        answer: this.Anquery,
      }).then((res) => {
        if (res.message == "请求成功") {
          this.$message({
            message: "发送成功",
            type: "success",
          });
          this.list();
          this.Anquery = "";
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //list
    list() {
      var obj = {
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        query: {
          classId: this.$route.query.classId,
          themeId: this.value,
        },
      };
      questionAnswerGetQuestionAnswerPage(obj).then((res) => {
        this.page.total = res.data.total;
        this.lists = res.data.list;
        console.log(res);
      });
    },
    OnReset(val) {
      if (val==1) {
        this.value=''
      }
      this.page.pageNum = 1;
      this.list();
    },
    //提问按钮--
    OnSet(num, index, row) {
      if (num == 1) {
        this.dialog.title = "提出疑问";
        this.formLabelAlign = {
          subject: this.subjectList[0].label, //科目
          special: "", //专题
          query: "", //疑问
          id: null,
        };
      } else {
      }
      this.dialog.dialogVisible = true;
    },
    //提问答疑
    OnDialogSet(type) {
      if (type == 1) {
        this.dialog.dialogVisible = false;
        return;
      }
      this.$refs.formLabelAlign.validate((valid) => {
        if (valid) {
          if (this.dialog.title == "提出疑问") {
            var obj = {
              themeId: this.formLabelAlign.special,
              classId: this.$route.query.classId,
              title: this.formLabelAlign.query,
            };
            if (obj.title == "") {
              this.$message.error("内容不可为空");
              return;
            }
            questionAnswerCreateQuestion(obj).then((res) => {
              console.log(res);
              if (res.message == "请求成功") {
                this.$message({
                  message: "发送成功",
                  type: "success",
                });
                this.dialog.dialogVisible = false;
                this.list();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            //..
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.list();
    },
  },
};
</script>
<style lang='less' scoped>
@import "./index.less";
/deep/.el-scrollbar {
  height: 100%;
}
</style>
